<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 one-click-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="one-click-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_one-click-contacts-help-online'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name:
                $route.params && $route.params.backLinkName
                  ? $route.params.backLinkName
                  : 'r_one-click-settings'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title" v-if="widgets && widgets.length">
          {{ widgets.find(el => el.widget_id === 3).name }}
        </div>
        <template v-slot:right
          ><button @click="search = !search">
            <icon icon="#cx-hea1-search" />
          </button>
          <button @click="saveContacts">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="searchQuery = ''"
        :simpleSearch="true"
      ></search>
    </template>
    <Draggable
      v-if="listOfContacts && listOfContacts.length"
      :list="listOfContacts"
      item-key="id"
      tag="ul"
      class="clebex-item-section pill"
    >
      <template #item="{element}">
        <li class="clebex-item-section-item" v-if="element.is_active">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    :id="`oneClickContacts${element.id}`"
                    type="checkbox"
                    v-model="element.is_active"
                  />
                  <label :for="`oneClickContacts${element.id}`"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd">
                <button class="cursor-default">
                  {{ element.first_name }} {{ element.last_name }}
                </button>
              </dd>
            </dl>
          </div>
        </li>
      </template>
    </Draggable>
    <ul
      v-if="listOfContacts && listOfContacts.length"
      class="clebex-item-section pill"
    >
      <li
        v-for="contact in listOfContacts.filter(el => !el.is_active)"
        :key="contact.id"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  :id="`oneClickContacts${contact.id}`"
                  type="checkbox"
                  v-model="contact.is_active"
                />
                <label :for="`oneClickContacts${contact.id}`"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd">
              <button class="cursor-default">
                {{ contact.first_name }} {{ contact.last_name }}
              </button>
            </dd>
          </dl>
        </div>
      </li>
    </ul>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "OneClickContacts",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Draggable: defineAsyncComponent(() => import("vuedraggable")),
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      search: false,
      searchQuery: "",
      helpSlug: "one-click-settings-find"
    };
  },
  created() {
    this.getContacts();
  },
  computed: {
    ...mapState("oneClick", ["widgets", "contacts"]),
    listOfContacts() {
      const query = this.searchQuery;
      if (this.contacts && this.contacts.length) {
        if (query && query.length > 1) {
          return this.contacts.filter(
            item =>
              item.first_name.toLowerCase().includes(query.toLowerCase()) ||
              item.last_name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.contacts;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("oneClick", ["getContacts", "setPersons"]),
    saveContacts() {
      const postArray = [];
      let ordering = 1;
      this.contacts.forEach(element => {
        if (element.is_active) {
          postArray.push({
            id: element.id,
            ordering: ordering
          });
        }
        ordering++;
      });
      this.setPersons(postArray);
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    }
  }
};
</script>
